import React, { useState } from "react"
import { API, graphqlOperation } from "aws-amplify"
import {
  Modal,
  trackRejectOffer,
  getConvertedPlanPeriod,
  isEmpty,
  client,
  captureException,
  SENTRY_ACTIONS,
} from "@lesmills/gatsby-theme-common"
import { getOffer } from "../../utils/memberPromo"
import { getPromoCodeInformation } from "../../graphql/getPromoCodeInformation"
import type { MemberPromoPopupType } from "../../types/MemberPromoPopupType"

import MemberPromoContent from "./MemberPromoContent"

type Props = {|
  handleDismiss: () => void,
  handleCancelSubscription: () => void,
  prismicData: MemberPromoPopupType,
  currentSubscription: {},
  isProcessing: Boolean,
|}

const MemberPromoModalWrapper = ({
  handleDismiss,
  handleCancelSubscription,
  prismicData,
  currentSubscription,
  isProcessing,
}: Props) => {
  const [offerType, setOfferType] = useState("first")
  const [isSecondOfferEligible, setIsSecondOfferEligible] = useState(false)
  const [isFirstOfferRejectLoading, setIsFirstOfferRejectLoading] = useState(
    false
  )

  const handleRejectFirstOffer = async () => {
    if (firstOfferData?.has_second_offer) {
      try {
        client()
        setIsFirstOfferRejectLoading(true)
        const { data } = await API.graphql(
          graphqlOperation(getPromoCodeInformation, {
            promoCode: secondOfferData?.promo_code,
          })
        )

        setIsFirstOfferRejectLoading(false)
        if (data?.getPromoCodeInformation) {
          setIsSecondOfferEligible(true)
          setOfferType("second")
        } else {
          setIsSecondOfferEligible(false)
          handleCancelSubscription("memberPromoModal")
          await trackRejectOffer()
        }
      } catch (error) {
        setIsFirstOfferRejectLoading(false)
        captureException({
          action: SENTRY_ACTIONS.MEMBER_PROMO_VALIDATION,
          ...error,
        })
        setIsSecondOfferEligible(false)
        handleCancelSubscription("memberPromoModal")
        await trackRejectOffer()
      }
    } else {
      handleCancelSubscription("memberPromoModal")
      await trackRejectOffer()
    }
  }

  const handleRejectSecondOffer = async () => {
    handleCancelSubscription("memberPromoModal")
    await trackRejectOffer()
  }

  const currentSubscriptionProductName = getConvertedPlanPeriod(
    currentSubscription?.product?.product_price_point?.interval,
    currentSubscription?.product?.product_price_point?.interval_unit
  )

  const firstOfferData = prismicData?.first_offer?.length
    ? getOffer(prismicData?.first_offer, currentSubscriptionProductName)
    : {}
  const secondOfferData = prismicData?.second_offer?.length
    ? getOffer(prismicData?.second_offer, currentSubscriptionProductName)
    : {}

  return (
    <Modal
      handleDismiss={handleDismiss}
      classNames={{
        wrapper: "bg-white md:bg-modal-base",
        content:
          "p-0 sm:w-640 sm:m-0 sm:top-1/2 sm:left-1/2 mx-16 my-16 rounded-lg inset-x-50-16 inset-y-50-16",
      }}
      isCustomisedContentPosition={true}
      testId="member-promo-modal"
      nonePadding={true}
      corssButtonTheme="white"
      heightAuto={true}
    >
      {!isEmpty(firstOfferData) && offerType === "first" && (
        <MemberPromoContent
          data={firstOfferData}
          handleRejectOffer={handleRejectFirstOffer}
          isProcessing={isProcessing || isFirstOfferRejectLoading}
        />
      )}
      {!isEmpty(secondOfferData) &&
        offerType === "second" &&
        isSecondOfferEligible && (
          <MemberPromoContent
            data={secondOfferData}
            handleRejectOffer={handleRejectSecondOffer}
            isProcessing={isProcessing}
          />
        )}
    </Modal>
  )
}

export default MemberPromoModalWrapper
